<template>
  <div class="goods-item">
    <div class="pic" v-if="isShowPic">
      <img v-lazy="pic" alt />
    </div>
    <div class="info" :class="{'active':!isShowPic}">
      <h5>{{title}}</h5>
      <div class="man" v-if="type==0">
        <p>
          现价:
          <span class="red">￥{{price}}</span>
        </p>
        <p>
          领先者:
          <span>{{buyer}}</span>
        </p>
      </div>
      <div class="time line" v-if="type==0&&status==0">
        <i></i>
        距离结束：
        <span class="red">{{count_txt}}</span>
      </div>

      <div class="block line" v-if="type==0&&status==1"></div>

      <div class="price" v-if="type==1&&isShowPic">
        <p>
          一口价:
          <span class="red">￥{{price}}</span>
        </p>
        <p>
          上新时间:
          <span>{{date.split(" ")[0]}}</span>
        </p>
      </div>

      <div class="price line" v-if="type==1&&!isShowPic">
        <p>
          一口价:
          <span class="red">￥{{price}}</span>
        </p>
        <p>
          上新时间:
          <span>{{date}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormItem",
  data() {
    return {
      show:true
    };
  },
  props: {
    late:{
      type: [Number,String],
      default: 0
    },
    type: {
      type: [Number,String],
      default: 0
    },
    isShowPic: {
      type: Boolean,
      default: true
    },
    pic: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    count: {
      type: [String,Number],
      default: ""
    },
    buyer: {
      type: String,
      default: ""
    },
    price: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    },
    status: {
      type: [String,Number],
      default: ""
    },
  },
  created() {},
  mounted() {

  },
  computed: {
    count_txt() {
      if (this.count == "" || this.count<0) return "检测延时";
      return this.changeDate(this.count)+(this.late==1?"(延时)":"");;
    }
  },
  methods: {
    input() {
      this.$emit("input", event.target.value);
    },
    changeDate(n) {
      let haoscend = n % 1000; //毫秒
      let scend = parseInt((n / 1000) % 60); //秒
      let minute = parseInt((n / 1000 / 60) % 60); //  分钟
      let hour = parseInt((n / 1000 / 60 / 60) % 24); //小时
      let day = Math.floor( n/1000 / (24*3600) ); //天数
      let txt = day > 0 ? `${day}天` : "";
      txt += hour>0 ? `${hour<10?"0"+hour:hour}小时`:"";
      txt += minute>0 ? `${minute<10?"0"+minute:minute}分`:"";
      txt += `${scend<10?"0"+scend:scend}秒`;
      return txt;
    }
  },
  watch: {

  }
};
</script>

<style lang="scss" scoped>
.block{
  height: 1px;
  position: relative;
  width: 100%;
  margin-bottom: .2rem;
}
.goods-item {
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding: 0.2rem 0.3rem 0;

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    &.active {
      .line {
        padding-top: 0;
        padding-bottom: 0.27rem;
        &::before {
          top: inherit;
          bottom: -1px;
        }
      }
    }
    .price {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      padding-bottom: 0.2rem;
      p {
        font-size: 0.22rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.39rem;
        span {
          font-size: 0.22rem;
          color: #333333;
          margin-left: 0.04rem;
        }
        .red {
          color: #ff1010;
        }
      }
    }
    .time {
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      padding-bottom: 0.2rem;
      span {
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.39rem;
        .red {
          color: #ff5959;
        }
      }
      i {
        width: 0.26rem;
        height: 0.26rem;
        background: url("../assets/images/icon_time.png") no-repeat;
        background-size: 100% auto;
        display: inline-block;
        margin-right: 0.14rem;
      }
    }
    .line {
      position: relative;
      box-sizing: border-box;
      padding-top: 0.16rem;
      &::before {
        content: "";
        display: block;
        height: 1px;
        background: #e1e1e1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: scaleY(0.5);
      }
    }
    .man {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;
      padding-bottom: 0.16rem;
      p {
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.39rem;
        span {
          font-size: 0.26rem;
          color: #333333;
          margin-left: 0.04rem;
        }
        .red {
          color: #ff1010;
        }
      }
    }
    h5 {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 0.39rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      box-sizing: border-box;
      margin-bottom: 0.22rem;
    }
  }
  .pic {
    width: 2.3rem;
    height: 2.3rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0.08rem;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    margin-right: 0.24rem;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>