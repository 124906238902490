<template>
  <div class="home-page">
    <Warpper bottom="60">
      <Container top="0" ref="container" :bounceBottom="true" style="background: #eff0ef">
        <div class="home-logo-head">
          <img src="../../assets/images/logo.png" alt="logo" class="logo" />
          <div class="home-search">
            <img src="../../assets/images/home_btn_search.png" alt srcset />
            <input type="search" @keyup.13="onSearch" placeholder="输入关键字" v-model="searchValue" />
          </div>
          <img src="../../assets/images/btn_tongzhi.png" alt class="news" @click.stop="toNotice" />
          <img
            @click.stop="toMine"
            v-if="userInfo && userInfo.pic"
            :src="userInfo.pic"
            alt
            class="face"
          />
          <img v-else src="../../assets/images/face.jpg" alt class="face" @click.stop="toLogin" />
        </div>

        <ul class="home-nav-head">
          <li :class="{ active: navIndex == 0 }" @click.stop="navIndex = 0">
            <img src="../../assets/images/home_icon_auction.png" />一元拍
          </li>
          <li :class="{ active: navIndex == 1 }" @click.stop="navIndex = 1">
            <img src="../../assets/images/home_icon_price.png" />一口价
          </li>
        </ul>

        <div class="tab-scroller" ref="scroller">
          <ul>
            <li
              :class="{ active: typeIndex == index }"
              @click.stop="changeTypeBook(index)"
              v-for="(item, index) in typeList"
              :key="index"
            >
              {{ item.title }}
              <span v-if="item.id != null">({{ item.num }})</span>
            </li>
          </ul>
        </div>

        <ul class="query-nav">
          <li @click.stop="status_sort_show = !status_sort_show">
            {{ status_sort == 0 ? status_txt : "已结束" }}
            <i></i>
            <ul v-show="status_sort_show">
              <li
                @click.stop="
                  status_sort = 0;
                  status_sort_show = false;
                "
              >{{ status_txt }}</li>
              <li
                @click.stop="
                  status_sort = 1;
                  status_sort_show = false;
                "
              >已结束</li>
            </ul>
          </li>
          <li
            @click.stop="
              price_sort = price_sort == 'price_up' ? 'price_down' : 'price_up'
            "
            :class="{ up: price_sort == 'price_up' }"
          >
            价格排序
            <i></i>
          </li>
          <li v-if="navIndex == 0" @click.stop="stage_show_box = true">专场筛选</li>
          <li
            v-if="navIndex == 1"
            @click.stop="
              time_sort = time_sort == 'time_up' ? 'time_down' : 'time_up'
            "
            :class="{ up: time_sort == 'time_up' }"
          >
            上书时间
            <i></i>
          </li>
        </ul>
        <ul class="item-type-nav">
          <li
            @click.stop="item_type = index"
            :class="{ active: item_type == index }"
            v-for="(item, index) in ['列表', '图文']"
            :key="index"
          >{{ item }}</li>
        </ul>

        <!-- <div class="stage-box">
          <div
            class="stage-title"
            v-if="navIndex == 0 && stageIndex != -1"
          >{{ stageList[stageIndex].name }}</div>
        </div> -->

        <!-- <div class="stage-box">
          <div
            class="stage-title"
            v-if="stageObj"
          >{{ stageObj.name }}</div>
        </div> -->

        <div class="goods-list-box">
          <div v-for="item in goodsList" :key="item.id" @click="toDetail(item.id)">
            <div class="stage-box">
              <div
                class="stage-title"
                v-if="item.stageName"
              >{{ item.stageName }}</div>
            </div>
            <GoodsItem
              :type="navIndex"
              :isShowPic="!!item_type"
              :pic="item.pic"
              :title="item.title"
              :count="item.count"
              :buyer="item.touser_nickname"
              :price="item.price"
              :date="item.date"
              :status="status_sort"
              :late="item.late_state"
            />
          </div>
        </div>

        <None class="y-none" value="没有更多数据啦" v-if="isloaded && goodsList.length == 0" />

        <div class="bottom-none" v-if="isloaded && goodsList.length > 0 && navIndex == 0">没有更多数据啦</div>
      </Container>
    </Warpper>

    <div class="mask" v-show="mask"></div>
    <div class="stage-show-box" v-if="stage_show_box">
      <h5>选择专场</h5>
      <ul>
        <li :class="{ active: stageIndex == -1 }" @click.stop="stageIndex = -1">全部专场</li>
        <li
          :class="{ active: stageIndex == index }"
          @click.stop="stageIndex = index"
          v-for="(item, index) in stageList"
          :key="index"
        >{{ item.name }}</li>
      </ul>
      <div @click.stop="stage_show_box = false">关闭</div>
    </div>

    <div @click.stop="toCart" class="cart-btn" v-if="navIndex==1">
      <div>{{cartCount}}</div>
    </div>

    <Tabber selected="0" />
  </div>
</template>

<script>
import None from "components/None";
import Tabber from "components/Tabbar";
import GoodsItem from "components/GoodsItem";
import BScroll from "@better-scroll/core";
import MyMqtt from "@/plugins/mqtt";
import {
  reqHomeTypeList,
  reqHomeClassList,
  reqHomeBookList,
  reqGetCartInfo
} from "@/network/api";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || null,
      navIndex: 0,
      searchValue: "",
      typeIndex: -1,
      typeList: [],
      price_sort: "", // price_up price_down,
      time_sort: "", // time_up time_down,
      status_sort: 0, // 0 已拍卖 1 已结束
      stage_sort: "",
      status_sort_show: false,
      stage_show_box: false,
      mask: false,
      stageIndex: -1,
      stageList: [],
      item_type: 0,
      isloaded: false,
      goodsList: [],
      sortName: null,
      now: 1,
      count: 1,
      stageObj:null,
      isSend:false,
      cartCount:0
    };
  },
  components: {
    Tabber,
    None,
    GoodsItem
  },
  computed: {
    status_txt() {
      return ["正在拍卖", "正在出售"][this.navIndex] || "正在拍卖";
    }
  },
  async activated() {
    console.log("激活");
    if(this.navIndex==1) this.getCartInfo();
    let mqttList =this.$store.getters.mqttList;
    mqttList.forEach(item => {
      this.onMessage(item);
    });
    await this.$store.dispatch("other/clearMqttList");
    this.mqtt = new MyMqtt();
    this.mqtt.init();
    this.mqtt.message = this.onMessage;
    this.userInfo = this.$store.getters.userInfo;
    this.$nextTick(() => {
      this.refreshScroll();
    });
  },
  deactivated() {
    console.log("销毁");
    this.mqtt.end();
  },
  created() {
    setTimeout(()=>{
      this.userInfo = this.$store.getters.userInfo;
    });
  },
  mounted() {
    this.createScoller();
    this.getStageList();
    this.getTypeList().then(() => {
      this.getGoodsList();
      this.handlerPullUp();
    });
  },
  watch: {
    price_sort(v) {
      this.sortName = v;
    },
    time_sort(v) {
      this.sortName = v;
    },
    sortName(v) {
      this.isloaded = false;
      this.goodsList.length = 0;
      this.now = 1;
      this.count = 1;
      this.refreshData();
    },
    status_sort(v) {
      this.isloaded = false;
      this.goodsList.length = 0;
      this.now = 1;
      this.count = 1;
      this.refreshData();
    },
    item_type(v) {
      this.refreshScroll();
    },
    stage_show_box(v) {
      this.mask = v;
    },
    stageIndex(v) {
      this.isloaded = false;
      this.goodsList.length = 0;
      this.now = 1;
      this.count = 1;
      this.stage_show_box = false;
      this.refreshScroll();
      this.refreshData();
    },
    navIndex(v) {
      this.isloaded = false;
      this.goodsList.length = 0;
      this.price_sort = "";
      this.time_sort = "";
      this.stage_sort = "";
      this.status_sort = 0;
      this.stageIndex = -1;
      this.sortName = "";
      this.now = 1;
      this.count = 1;
      if (v == 0) this.typeIndex = -1;
      if (v == 1) {
        this.typeIndex = 0;
        this.getCartInfo();
      }
      this.$nextTick(() => {
        this.refreshScroll();
      });
      this.$refs.container && this.$refs.container.scroll.finishPullUp();
      this.getTypeList().then((res) => {        
        this.getGoodsList();
      });
    }
  },
  methods: {
    getCartInfo() {
      reqGetCartInfo({
        user_id: this.userInfo.id,
      },false).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return;
        this.cartCount = backdata.cart_count;
      });
    },
    toCart() {
      this.$router.push({
        name: "cart"
      });
    },
    onMessage(msg) {
      if (this.navIndex == 1) return;
      this.goodsList = [].map.call(this.goodsList, item => {
        if (msg.text.id == item.id) {
          item = this.changeItem(msg, item);
        }
        return item;
      });
    },
    changeItem(msg, item) {
      let obj = item;
      const { action, text } = msg;
      if (action == "edit" || action == "expire") {
        for (const key in text) {
            obj[key] = msg.text[key];
        }
        obj.price = obj.now_price;
      }
      if (action == "state") {
        setTimeout(() => {
          this.onDelItem(item.id);
        }, 5000);
      }
      return obj;
    },
    onDelItem(id) {
      if (this.navIndex == 1) return;
      this.goodsList = [].filter.call(this.goodsList,item => {
        return item.id != id;
      });
      if(this.goodsList.length==0){
        this.isloaded = false;
        this.goodsList.length = 0;
        this.now = 1;
        this.count = 1;
        this.refreshData();
      }
    },
    changeTypeBook(index) {
      this.typeIndex = index;
      this.isloaded = false;
      this.goodsList.length = 0;
      this.now = 1;
      this.count = 1;
      this.refreshData();
    },
    refreshData() {
      this.$refs.container && this.$refs.container.scroll.finishPullUp();
      this.getGoodsList();
    },
    toDetail(id) {
      // 跳转详情
      this.$router.push({
        name: "detail",
        query: {
          type: this.navIndex,
          id
        }
      });
    },
    handlerPullUp() {
      if (!this.$refs.container) return;
      let { scroll } = this.$refs.container;
      scroll.on("pullingUp", () => {
        this.getGoodsList(function() {
          scroll.finishPullUp();
        });
      });
    },
    refreshScroll() {
      this.$nextTick(() => {
        this.$refs.container && this.$refs.container.refresh();
      });
    },
    getGoodsList(callback) {
      if (this.isloaded) return;
      if(this.isSend) return;
      this.isSend = true;
      let options = {
        title:this.searchValue,
        state: this.status_sort, //状态，默认0，0在拍，1结束
        model: this.navIndex, //默认0，0拍卖，1一口价
        sort: this.sortName, //默认空，price_up价格升序，price_down价格降序，time_up时间由近到晚，time_down时间由晚到近
        type: this.typeIndex == -1 ? null : this.typeList[this.typeIndex].id, //分类id，默认空取全部
        pro_class:
          this.stageIndex == -1 ? "" : this.stageList[this.stageIndex].id, //拍场id，默认空取最近场次
        page: this.now++ //默认1，每页20条
      };

      if(this.navIndex==1&&options.type=="0"){
        delete options.type;
      }

      reqHomeBookList(options).then(res => {
        this.isSend = false;
        if (!res) {
          this.isloaded = true;
          return;
        }
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        const { count, list, now } = backdata;
        this.count = count;
        this.now = this.now;

        try {
          if (this.navIndex == 1){
            this.stageObj = null;
          }else{
            this.stageObj = backdata.class;
            this.stageObj.name = this.stageObj.classname;
          }
        } catch (error) {
          
        }
        
        let _list = [].map.call(list, item => {
          let _c = new Date(item.end_time).getTime() - new Date().getTime();
          item.count = _c <= 0 ? "" : _c.toString();
          item.price = item.now_price;
          item.buyer = item.touser_nickname;
          item.date = item.end_time;
          return item;
        });
        
        this.goodsList = [...this.goodsList, ..._list];

        let stageClass = "";
        this.goodsList = this.goodsList.map((item,index)=>{
           if(stageClass==item.classname){
            item.stageName = "";
           }else{
            stageClass = item.classname;
            item.stageName = item.classname;
          }
          return item;
        })
        this.isloaded = !!(this.now > this.count);
        this.countDown();
        this.$nextTick(() => {
          this.refreshScroll();
          callback && callback();
        });
      }).catch(()=>{
        this.isSend = false;
      });
    },
    countDown() {
      if (this.timer) clearInterval(this.timer);
      if (this.navIndex == 1) return;
      this.timer = setInterval(() => {
        this.goodsList = [].map.call(this.goodsList, item => {
          let _c = new Date(item.end_time.replace(/-/g, "/")).getTime() - new Date().getTime();
          item.count = _c <= 0 ? "" : _c.toString();
          return item;
        });
      }, 1000);
    },
    getStageList() {
      reqHomeClassList({
        onstate: 1
      }).then(res => {
        const { code, backdata } = res;
        if (code != 0) return;
        const { list } = backdata;
        this.stageList = [].map.call(list, item => {
          item.name = item.classname;
          return item;
        });
      });
    },
    getTypeList() {
      return new Promise((resolve, reject) => {
        reqHomeTypeList({
          state: this.status_sort,
          model: this.navIndex
        })
          .then(res => {
            const { code, backdata } = res;
            if (backdata) {
              const { type_list } = backdata;
              // console.log(backdata);
              this.typeList = [].map.call(type_list, item => {
                item.num = item.count;
                return item;
              });
              if (this.navIndex == 1)
                this.typeList.unshift({ id: null, title: "全部" });
              this.$nextTick(() => {
                this.tabScroll && this.tabScroll.refresh();
              });
            }
          })
          .then(() => {
            resolve();
          });
      });
    },
    toNotice() {
      this.$router.push({
        name: "notice"
      });
    },
    toLogin() {
      this.$router.push({
        name: "login"
      });
    },
    toMine() {
      this.$router.push({
        name: "mine"
      });
    },
    createScoller() {
      this.tabScroll = new BScroll(this.$refs.scroller, {
        scrollX: true,
        scrollY: false,
        bounceTime: 200,
        bounce: {
          top: false,
          bottom: false,
          left: true,
          right: true
        }
      });
      this.$nextTick(() => {
        this.tabScroll && this.tabScroll.refresh();
      });
    },
    onSearch() {
      // if (this.searchValue == "") return;
      this.isloaded = false;
      this.goodsList.length = 0;
      this.price_sort = "";
      this.time_sort = "";
      this.stage_sort = "";
      this.status_sort = 0;
      this.stageIndex = -1;
      this.sortName = "";
      this.now = 1;
      this.count = 1;
      if (this.navIndex == 0) this.typeIndex = -1;
      if (this.navIndex == 1) this.typeIndex = 0;
      this.$nextTick(() => {
        this.refreshScroll();
      });
      this.$refs.container && this.$refs.container.scroll.finishPullUp();
      this.getTypeList().then((res) => {
        this.getGoodsList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-btn {
  width: 1.33rem;
  height: 1.33rem;
  background: url(../../assets/images/right_btn_shopping.png) no-repeat;
  background-size: 100% auto;
  position: absolute;
  right: 0.2rem;
  bottom: 2.02rem;
  z-index: 99;
  cursor: pointer;
  & > div {
    width: 0.27rem;
    height: 0.27rem;
    position: absolute;
    background: #ec4e4b;
    border-radius: 50%;
    font-size: 0.16rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.32rem;
    top: 0.18rem;
  }
}
.y-none {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 1.5rem;
}
.goods-list-box {
  width: 100%;
  padding: 0 0rem;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
}
.bottom-none {
  background: #eff0ef;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.24rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.item-type-nav {
  display: flex;
  align-items: center;
  height: 0.8rem;
  box-sizing: border-box;
  padding: 0 0.3rem;
  background-color: #fff;
  li {
    margin-right: 0.05rem;
    width: 1.04rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.04rem;
    cursor: pointer;
    background-color: transparent;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #50a095;
    position: relative;
    padding-bottom: .1rem;
    margin-top: .1rem;
    &.active {
      color: #fff;
      font-size: .32rem;
      font-weight: bold;
      color: #50A095;
      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: .06rem;
        background: linear-gradient(-90deg, #4E9E93 0%, #6BB4AB 100%);
      }
    }
  }
}
.stage-box {
  background-color: #fff;
  box-sizing: border-box;
  padding-top: .1rem;
}
.stage-title {
  width: 6.9rem;
  height: 0.88rem;
  background: #8ec6bf;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.28rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #007867;
  margin: 0 0.32rem;
  &::after {
    content: "";
    background: url("../../assets/images/list_btn_down.png") no-repeat;
    background-size: 100% auto;
    width: 0.22rem;
    height: 0.1rem;
    position: absolute;
    bottom: -0.08rem;
    left: 50%;
    margin-left: -0.11rem;
    z-index: 2;
  }
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 788;
  background-color: rgba(0, 0, 0, 0.4);
}
.stage-show-box {
  width: 5.3rem;
  height: 6.84rem;
  background: #ffffff;
  border-radius: 0.08rem;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -(5.3rem/2);
  margin-top: -(6.84rem/2);
  z-index: 789;
  display: flex;
  flex-direction: column;
  h5 {
    width: 100%;
    height: 0.88rem;
    background: #8ec6bf;
    border-radius: 0.08rem 0.08rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #007867;
  }
  ul {
    flex: 1;
    overflow: auto;
    li {
      height: 0.88rem;
      display: flex;
      padding: 0.3rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      position: relative;
      align-items: center;
      &.active::after {
        content: "";
        background: url("../../assets/images/icon_choice_red.png") no-repeat;
        background-size: 100% auto;
        width: 0.24rem;
        height: 0.16rem;
        margin-left: 0.14rem;
        display: inline-block;
      }
      &:not(:last-of-type)::before {
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        transform: scaleY(0.5);
        height: 1px;
        background: #e1e1e1;
      }
    }
  }
  div {
    height: 0.87rem;
    font-size: 0.32rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
      content: "";
      top: 0;
      position: absolute;
      left: 0;
      right: 0;
      transform: scaleY(0.5);
      height: 1px;
      background: #e1e1e1;
    }
  }
}
.query-nav {
  height: 1.05rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  li {
    flex: 1;
    cursor: pointer;
    position: relative;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    ul {
      width: 2.3rem;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      position: absolute;
      top: 0.75rem;
      left: 50%;
      margin-left: -(2.3rem/2);
      box-sizing: border-box;
      z-index: 9;
      li {
        height: 0.66rem;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-of-type)::after {
          content: "";
          display: block;
          bottom: 0;
          height: 1px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background: #e1e1e1;
        }
      }
    }
    &.up {
      i {
        transform: scaleY(-1);
        top: -0.03rem;
      }
    }
    i {
      width: 0;
      height: 0;
      margin-left: 0.14rem;
      box-sizing: border-box;
      border-width: 0.11rem 0.1rem;
      position: relative;
      top: 0.06rem;
      border-style: solid;
      border-color: #000000 transparent transparent transparent;
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    height: 1px;
    background: #e1e1e1;
  }
}
.tab-scroller {
  height: 0.88rem;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(0deg, #ffffff 0%, #efefef 100%);
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleY(0.5);
    height: 1px;
    background: #e1e1e1;
  }
  ul {
    width: auto;
    height: 100%;
    padding: 0 0.08rem;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    li {
      display: inline-block;
      margin: 0 0.22rem;
      font-size: 0.24rem;
      height: 100%;
      line-height: 0.88rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      span {
        color: inherit;
        font-size: 0.24rem;
      }
      &.active {
        color: #4e9e93;
      }
    }
  }
}
.home-nav-head {
  width: 100%;
  height: 1.26rem;
  display: flex;
  background: #ffffff;
  li {
    flex: 1;
    opacity: 0.4;
    height: 1.16rem;
    background: linear-gradient(87deg, #6bb4ab 0%, #4e9e93 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    font-size: 0.32rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    &.active {
      opacity: 1;
    }
    img {
      margin-right: 0.18rem;
      width: 0.7rem;
      height: 0.7rem;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 0.1rem;
      position: absolute;
      bottom: -0.1rem;
      background: linear-gradient(90deg, #fffe00 0%, #fec600 100%);
    }
  }
}
.home-logo-head {
  width: 100%;
  height: 1.2rem;
  background-color: #fff;
  box-sizing: border-box;
  align-items: center;
  padding-left: 0.29rem;
  display: flex;
  .logo {
    width: 1.21rem;
    height: auto;
    margin-right: 0.18rem;
  }
  div {
    width: 2.5rem;
    height: 0.62rem;
    border: 1px solid #e1e1e1;
    border-radius: 0.31rem;
    margin-right: 0.27rem;
    box-sizing: border-box;
    padding: 0 0.24rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    img {
      width: 0.32rem;
      height: 0.32rem;
      margin-right: 0.16rem;
    }
    input {
      height: 0.32rem;
      width: 1.6rem;
      outline: 0;
      border: 0;
      font-size: 0.26rem;
    }
  }
  .news {
    width: 1.65rem;
    height: auto;
    margin-right: 0.44rem;
  }
  .face {
    width: 0.6rem;
    height: 0.6rem;
    object-fit: cover;
    border-radius: 50%;
  }
}
.home-page {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
